import React from 'react';
import styled from 'styled-components';
import { Body1 } from './Typography';
import GreenTickIcon from '../assets/icons/tick.svg';

const Wrapper = styled.div``;

const Text = styled(Body1)<{ color?: string }>`
  color: ${({ color }) => (color ? `${color}` : `initial`)};
`;

const DescriptionItem = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;
`;

const GreenTick = styled(GreenTickIcon)<{ color?: string }>`
  margin-right: 0.7rem;
  color: ${({ color }) => (color ? `${color}` : `#2cd19e`)};
  @media (max-width: 1200px) {
    flex-shrink: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center; ;
`;

interface TickedItemsProps {
  items: {
    item: string;
  }[];
  textColor?: string;
  tickColor?: string;
}

const TickedItems: React.FunctionComponent<TickedItemsProps> = ({
  items,
  textColor,
  tickColor,
}) => (
  <Wrapper>
    {items.map((el) => (
      <DescriptionItem key={el.item}>
        <IconWrapper>
          <GreenTick color={tickColor} />
        </IconWrapper>
        <Text color={textColor}>{el.item}</Text>
      </DescriptionItem>
    ))}
  </Wrapper>
);

export default TickedItems;
